import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const MONTHS_ARRAY = [
  { id: 1, name: marker('common.constants.months.january') },
  { id: 2, name: marker('common.constants.months.february') },
  { id: 3, name: marker('common.constants.months.march') },
  { id: 4, name: marker('common.constants.months.april') },
  { id: 5, name: marker('common.constants.months.may') },
  { id: 6, name: marker('common.constants.months.june') },
  { id: 7, name: marker('common.constants.months.july') },
  { id: 8, name: marker('common.constants.months.august') },
  { id: 9, name: marker('common.constants.months.september') },
  { id: 10, name: marker('common.constants.months.october') },
  { id: 11, name: marker('common.constants.months.november') },
  { id: 12, name: marker('common.constants.months.december') },
];

export const MONTHS: Record<number, string> = {
  0: marker('common.constants.months.january'),
  1: marker('common.constants.months.february'),
  2: marker('common.constants.months.march'),
  3: marker('common.constants.months.april'),
  4: marker('common.constants.months.may'),
  5: marker('common.constants.months.june'),
  6: marker('common.constants.months.july'),
  7: marker('common.constants.months.august'),
  8: marker('common.constants.months.september'),
  9: marker('common.constants.months.october'),
  10: marker('common.constants.months.november'),
  11: marker('common.constants.months.december'),
};

const DEFAULT_YEAR_FOR_HIDE_OLD = 2999;
export const DEFAULT_YEAR_FOR_HIDE = 1900;
export const ARRAY_YEARS_FOR_HIDE = [DEFAULT_YEAR_FOR_HIDE, DEFAULT_YEAR_FOR_HIDE_OLD];
