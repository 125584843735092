<div class="discount">
  <div class="discount__code">
    <ng-container *ngIf="!code?.discountQRCodeFileId; else qrCodeFileTmp">
      <app-scalable-font class="promoCode" [text]="code.code ?? 'common.labels.noData' | translate"></app-scalable-font>

      <button tuiButton type="button" appearance="secondary" size="m" (click)="copyPromoCode()">
        {{ 'components.loyaltyDiscountDialog.buttons.copyCode' | translate }}
      </button>
    </ng-container>

    <ng-template #qrCodeFileTmp>
      <app-photo
        [photoId]="code.discountQRCodeFileId"
        [unionId]="externalUnionId"
        [text]="'QR code image'"
        [width]="qrCodeImageWidth + 'px'"
      ></app-photo>
    </ng-template>
  </div>

  <div class="discount__terms">
    <h1 class="title">{{ 'components.loyaltyDiscountDialog.labels.terms' | translate }}:</h1>

    <app-parse-links
      class="description"
      [value]="code.discountOptions"
      (externalLink)="saveRedirectLink($event)"
    ></app-parse-links>
  </div>
</div>
