import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { SendByNotification, SendByNotificationCodeEnum } from './types';

export const SEND_BY_NOTIFICATIONS: SendByNotification[] = [
  {
    code: SendByNotificationCodeEnum.sendByDefault,
    default: true,
    notShowFor: ['onBirthdaysDaily'],
    title: marker('components.notifications.constants.sendByDefaultTitle'),
    description: marker('components.notifications.constants.sendByDefaultDescription'),
  },
  {
    code: SendByNotificationCodeEnum.sendByEmail,
    title: marker('components.notifications.constants.sendByEmailTitle'),
  },
  {
    code: SendByNotificationCodeEnum.sendByPush,
    notShowFor: ['onNewsletterCreated', 'onLoyaltyNewsletter'],
    title: marker('components.notifications.constants.sendByPushTitle'),
  },
  {
    code: SendByNotificationCodeEnum.sendByBot,
    title: marker('components.notifications.constants.sendByBotTitle'),
  },
];
