import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiFormatPhonePipeModule,
  TuiGroupModule,
  TuiHostedDropdownModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiDropdownModule,
} from '@taiga-ui/core';
import {
  TuiAvatarModule,
  TuiCheckboxBlockModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { TuiActiveZoneModule, TuiAutoFocusModule } from '@taiga-ui/cdk';
import { TUI_EDITOR_EXTENSIONS, TuiEditorModule, defaultEditorExtensions } from '@tinkoff/tui-editor';
import { ListModule } from '@src/app/shared/list';
import { SharedModule } from '@src/app/shared';
import { MessageListModule } from '@src/app/modules/message-list';
import { MessageModule } from '@src/app/modules/message';
import { GroupInfoModule } from '@src/app/modules/group-info';
import { SearchGroupsModule } from '@src/app/shared/search-groups';
import { ComponentsModule } from '@src/app/components';
import { LogoModule } from '@src/app/modules/branding/modules';
import { WaitTelegramChatsModule } from '@src/app/modules/wait-telegram-chats';
import { InputCodeModule } from '@src/app/modules/input-code';
import { IconModule } from '@src/app/modules/icon';
import { PhoneModule } from '@src/app/modules/phone';
import { DialogsModule } from '@src/app/shared/dialogs';

import { CustomNamesTabsModule } from '../custom-name-tabs';

import { UserModule } from './ui/user';
import { GroupModule } from './ui/group';
import { MediaRecorderModule } from './ui/media-recorder';
import { PinnedMessageListModule } from './ui/pinned-message-list';
import { ForwardModule } from './ui/forward';
import { BotModule } from './ui/bot';
import { AttachmentModule } from './ui/attachment';
import { ChatsComponent } from './ui/chats/chats.component';
import { NewsFeedComponent } from './ui/news-feed/news-feed.component';
import { ChatComponent } from './ui/chat/chat.component';
import { FoldersComponent } from './ui/folders/folders.component';
import { LoginComponent } from './ui/login/login.component';
import { CheckAuthComponent } from './ui/check-auth/check-auth.component';
import { ConversationBoxInputComponent } from './ui/chat/components/conversation-box-input/conversation-box-input.component';
import { DialogConfirmLoginComponent } from './ui/dialog-confirm-login/dialog-confirm-login.component';

@NgModule({
  declarations: [
    NewsFeedComponent,
    ChatComponent,
    ChatsComponent,
    FoldersComponent,
    LoginComponent,
    CheckAuthComponent,
    ConversationBoxInputComponent,
    DialogConfirmLoginComponent,
  ],
  imports: [
    CommonModule,
    TuiButtonModule,
    ListModule,
    LogoModule,
    ForwardModule,
    TuiAvatarModule,
    SharedModule,
    TuiGroupModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    PinnedMessageListModule,
    MessageListModule,
    MessageModule,
    AttachmentModule,
    TuiTextareaModule,
    FormsModule,
    TuiTextfieldControllerModule,
    MediaRecorderModule,
    TuiScrollbarModule,
    UserModule,
    BotModule,
    GroupModule,
    TuiCheckboxBlockModule,
    TuiDataListModule,
    TuiSvgModule,
    MatButtonModule,
    TuiInputModule,
    GroupInfoModule,
    SearchGroupsModule,
    MatListModule,
    MatIconModule,
    NgxPermissionsModule,
    RouterModule,
    TuiLoaderModule,
    TuiInputNumberModule,
    TuiAutoFocusModule,
    TuiInputPasswordModule,
    ComponentsModule,
    TuiDropdownModule,
    WaitTelegramChatsModule,
    TuiFormatPhonePipeModule,
    InputCodeModule,
    IconModule,
    PhoneModule,
    TuiEditorModule,
    TuiActiveZoneModule,
    CustomNamesTabsModule,
    DialogsModule,
  ],
  providers: [
    {
      provide: TUI_EDITOR_EXTENSIONS,
      useValue: defaultEditorExtensions,
    },
  ],
  exports: [LoginComponent, DialogConfirmLoginComponent],
})
export class TelegramModule {}
