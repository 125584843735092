import { Injectable, OnDestroy } from '@angular/core';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  InsertNotificationUserOptionsModel,
  NotificationEventsOptionsModel,
  NotificationUserSettingsService,
} from '@src/api';
import { AlertService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  notifications$ = new BehaviorSubject<Array<NotificationEventsOptionsModel> | undefined>(undefined);
  private destroyed$$ = new Subject<void>();
  private getNotifications$ = new BehaviorSubject<number | null>(null);
  private saveNotifications$ = new BehaviorSubject<Array<InsertNotificationUserOptionsModel> | null>(null);

  constructor(
    private notificationUserSettingsService: NotificationUserSettingsService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getData() {
    this.getNotifications().subscribe(notifications => this.notifications$.next(notifications ?? undefined));
    this.loadNotifications();
  }

  resetLoadNotifications() {
    this.getNotifications$.next(null);
  }

  loadNotifications() {
    this.getNotifications$.next(new Date().getTime());
  }

  getNotifications(): Observable<Array<NotificationEventsOptionsModel> | null> {
    return this.getNotifications$
      .pipe(filter(request => !!request))
      .pipe(
        switchMap(() => {
          return this.notificationUserSettingsService.apiNotificationUserSettingsGet().pipe(
            catchError(() => {
              this.alertService.error(
                this.translateService.instant('components.notifications.alerts.errors.getNotificationUserOptions'),
              );
              return of(null);
            }),
          );
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  startSaveNotifications(request: Array<InsertNotificationUserOptionsModel>) {
    this.saveNotifications$.next([...request]);
  }

  saveNotifications(): Observable<Array<string> | null> {
    return this.saveNotifications$
      .pipe(filter(request => !!request))
      .pipe(
        switchMap(request => {
          if (request !== null) {
            return this.notificationUserSettingsService.apiNotificationUserSettingsPost(request).pipe(
              catchError(() => {
                this.translateService.instant('components.notifications.alerts.errors.insertNotificationUserOptions');
                return of(null);
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }
}
