import { NotificationEventsOptionsModel } from '@src/api';
import { AbstractControl } from '@angular/forms';

// TODO: нужно создать на бэке перечисление и потом брать его из модели
export type NotificationEventCodeEnum =
  | 'onEventChanged'
  | 'onPollChanged'
  | 'onNewsletterCreated'
  | 'onLoyaltyNewsletter'
  | 'onBirthdaysDaily'
  | 'onRegistrationCreated';
export const NotificationEventCodeEnum = {
  onEventChanged: 'onEventChanged' as NotificationEventCodeEnum,
  onPollChanged: 'onPollChanged' as NotificationEventCodeEnum,
  onNewsletterCreated: 'onNewsletterCreated' as NotificationEventCodeEnum,
  onLoyaltyNewsletter: 'onLoyaltyNewsletter' as NotificationEventCodeEnum,
  onBirthdaysDaily: 'onBirthdaysDaily' as NotificationEventCodeEnum,
  onRegistrationCreated: 'onRegistrationCreated' as NotificationEventCodeEnum,
};

export type FormDataControls = { [key in keyof NotificationEventsOptionsModel]: AbstractControl };

export type SendByNotificationCodeEnum = 'sendByDefault' | 'sendByEmail' | 'sendByPush' | 'sendByBot';
export const SendByNotificationCodeEnum = {
  sendByDefault: 'sendByDefault' as SendByNotificationCodeEnum,
  sendByEmail: 'sendByEmail' as SendByNotificationCodeEnum,
  sendByPush: 'sendByPush' as SendByNotificationCodeEnum,
  sendByBot: 'sendByBot' as SendByNotificationCodeEnum,
};

export type SendByNotification = {
  code: SendByNotificationCodeEnum;
  default?: boolean;
  title: string;
  notShowFor?: string[]; // TODO: Убрать, когда будут реализованы пуши для рассылок и ПЛ на бэке
  description?: string;
};
