import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { VersionList } from './types';

export const VERSION_LIST: VersionList[] = [
  {
    name: 'v 2.14.0',
    date: '23.01.25',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_14_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_14_0.line9'),
    ],
  },
  {
    name: 'v 2.13.0',
    date: '19.11.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_13_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_13_0.line11'),
    ],
  },
  {
    name: 'v 2.12.0',
    date: '11.10.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_12_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_12_0.line11'),
    ],
  },
  {
    name: 'v 2.11.0',
    date: '19.09.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_11_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line11'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line12'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line13'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line14'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line15'),
      marker('components.changeLogPage.constants.versionList.v2_11_0.line16'),
    ],
  },
  {
    name: 'v 2.10.0',
    date: '22.07.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_10_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line11'),
      marker('components.changeLogPage.constants.versionList.v2_10_0.line12'),
    ],
  },
  {
    name: 'v 2.9.24',
    date: '25.06.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_24.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line11'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line12'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line13'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line14'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line15'),
      marker('components.changeLogPage.constants.versionList.v2_9_24.line16'),
    ],
  },
  {
    name: 'v 2.9.23',
    date: '21.04.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_23.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_23.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_23.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_23.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_23.line5'),
    ],
  },
  {
    name: 'v 2.9.22',
    date: '08.04.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_22.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_22.line10'),
    ],
  },
  {
    name: 'v 2.9.21',
    date: '14.03.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_21.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_21.line9'),
    ],
  },
  {
    name: 'v 2.9.20',
    date: '26.02.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_20.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line11'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line12'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line13'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line14'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line15'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line16'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line17'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line18'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line19'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line20'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line21'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line22'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line23'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line24'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line25'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line26'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line27'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line28'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line29'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line30'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line31'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line32'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line33'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line34'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line35'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line36'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line37'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line38'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line39'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line40'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line41'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line42'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line43'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line44'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line45'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line46'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line47'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line48'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line49'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line50'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line51'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line52'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line53'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line54'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line55'),
      marker('components.changeLogPage.constants.versionList.v2_9_20.line56'),
    ],
  },
  {
    name: 'v 2.9.12',
    date: '24.01.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_12.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_12.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_12.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_12.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_12.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_12.line6'),
    ],
  },
  {
    name: 'v 2.9.11',
    date: '17.01.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_11.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_11.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_11.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_11.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_11.line5'),
    ],
  },
  {
    name: 'v 2.9.10',
    date: '11.01.24',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_10.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line11'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line12'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line13'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line14'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line15'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line16'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line17'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line18'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line19'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line20'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line21'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line22'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line23'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line24'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line25'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line26'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line27'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line28'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line29'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line30'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line31'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line32'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line33'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line34'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line35'),
      marker('components.changeLogPage.constants.versionList.v2_9_10.line36'),
    ],
  },
  {
    name: 'v 2.9.0.2',
    date: '05.12.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line11'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line12'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line13'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line14'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line15'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_2.line16'),
    ],
  },
  {
    name: 'v 2.9.0_2.1',
    date: '20.11.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_0_1.line11'),
    ],
  },
  {
    name: 'v 2.9.0',
    date: '13.11.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_9_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line11'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line12'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line13'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line14'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line15'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line16'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line17'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line18'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line19'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line20'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line21'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line22'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line23'),
      marker('components.changeLogPage.constants.versionList.v2_9_0.line24'),
    ],
  },
  {
    name: 'v 2.8.8',
    date: '27.10.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_8_8.line1'),
      marker('components.changeLogPage.constants.versionList.v2_8_8.line2'),
      marker('components.changeLogPage.constants.versionList.v2_8_8.line3'),
    ],
  },
  {
    name: 'v 2.8.7',
    date: '17.10.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_8_7.line1'),
      marker('components.changeLogPage.constants.versionList.v2_8_7.line2'),
      marker('components.changeLogPage.constants.versionList.v2_8_7.line3'),
      marker('components.changeLogPage.constants.versionList.v2_8_7.line4'),
    ],
  },
  {
    name: 'v 2.8.6',
    date: '29.09.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_8_6.line1'),
      marker('components.changeLogPage.constants.versionList.v2_8_6.line2'),
      marker('components.changeLogPage.constants.versionList.v2_8_6.line3'),
      marker('components.changeLogPage.constants.versionList.v2_8_6.line4'),
      marker('components.changeLogPage.constants.versionList.v2_8_6.line5'),
    ],
  },
  {
    name: 'v 2.8.4',
    date: '08.09.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_8_4.line1'),
      marker('components.changeLogPage.constants.versionList.v2_8_4.line2'),
      marker('components.changeLogPage.constants.versionList.v2_8_4.line3'),
      marker('components.changeLogPage.constants.versionList.v2_8_4.line4'),
      marker('components.changeLogPage.constants.versionList.v2_8_4.line5'),
      marker('components.changeLogPage.constants.versionList.v2_8_4.line6'),
    ],
  },
  {
    name: 'v 2.8.0',
    date: '14.08.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_8_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line9'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line10'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line11'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line12'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line13'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line14'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line15'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line16'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line17'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line18'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line19'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line20'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line21'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line22'),
      marker('components.changeLogPage.constants.versionList.v2_8_0.line23'),
    ],
  },
  {
    name: 'v 2.6.0',
    date: '04.05.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_6_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_6_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_6_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_6_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_6_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_6_0.line6'),
    ],
  },
  {
    name: 'v 2.5.0',
    date: '26.04.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_5_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line4'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line5'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line6'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line7'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line8'),
      marker('components.changeLogPage.constants.versionList.v2_5_0.line9'),
    ],
  },
  {
    name: 'v 2.0.0',
    date: '15.03.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v2_0_0.line1'),
      marker('components.changeLogPage.constants.versionList.v2_0_0.line2'),
      marker('components.changeLogPage.constants.versionList.v2_0_0.line3'),
      marker('components.changeLogPage.constants.versionList.v2_0_0.line4'),
    ],
  },
  {
    name: 'v 1.110.0',
    date: '16.02.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_110_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line9'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line10'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line11'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line12'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line13'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line14'),
      marker('components.changeLogPage.constants.versionList.v1_110_0.line15'),
    ],
  },
  {
    name: 'v 1.105.0',
    date: '14.01.23',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_105_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line9'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line10'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line11'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line12'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line13'),
      marker('components.changeLogPage.constants.versionList.v1_105_0.line14'),
    ],
  },
  {
    name: 'v 1.96.0',
    date: '10.11.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_96_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_96_0.line7'),
    ],
  },
  {
    name: 'v 1.92.2',
    date: '13.10.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_92_2.line1'),
      marker('components.changeLogPage.constants.versionList.v1_92_2.line2'),
    ],
  },
  {
    name: 'v 1.92.0',
    date: '07.10.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_92_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line9'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line10'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line11'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line12'),
      marker('components.changeLogPage.constants.versionList.v1_92_0.line13'),
    ],
  },
  {
    name: 'v 1.83.0',
    date: '26.08.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_83_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line9'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line10'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line11'),
      marker('components.changeLogPage.constants.versionList.v1_83_0.line12'),
    ],
  },
  {
    name: 'v 1.81.0',
    date: '18.07.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_81_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line9'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line10'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line11'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line12'),
      marker('components.changeLogPage.constants.versionList.v1_81_0.line13'),
    ],
  },
  {
    name: 'v 1.77.0',
    date: '23.06.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_77_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_77_0.line8'),
    ],
  },
  {
    name: 'v 1.74.0',
    date: '02.06.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_74_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_74_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_74_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_74_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_74_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_74_0.line6'),
    ],
  },
  {
    name: 'v 1.73.0',
    date: '27.05.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_73_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_73_0.line9'),
    ],
  },
  {
    name: 'v 1.72.0',
    date: '18.05.22',
    changeList: [
      marker('components.changeLogPage.constants.versionList.v1_72_0.line1'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line2'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line3'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line4'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line5'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line6'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line7'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line8'),
      marker('components.changeLogPage.constants.versionList.v1_72_0.line9'),
    ],
  },
];
