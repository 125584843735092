import { TuiDay, TuiTime } from '@taiga-ui/cdk';

/**
 * Утилита для приведения типа [TuiDay, TuiTime] к типу Date
 *
 * @param {TuiDay} day
 * @param {TuiTime} time
 */
export const convertTuiDayAndTuiTimeToDate = (day?: TuiDay, time?: TuiTime): Date | undefined => {
  if (!day || !time) {
    return undefined;
  }

  const date = new Date(day.year, day.month, day.day, time.hours, time.minutes);

  return date;
};
