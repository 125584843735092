<tui-notification class="banner" status="info" [icon]="null" [routerLink]="link">
  <div class="banner__content">
    <app-icon *ngIf="icon" class="icon" [src]="icon"></app-icon>
    <div>
      <div class="title text_size-m">
        <strong>{{ title }}</strong>
      </div>
      {{ description }}
    </div>
  </div>
</tui-notification>
