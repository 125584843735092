import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TuiNotificationModule } from '@taiga-ui/core';
import { IconModule } from '@src/app/modules/icon';

import { BannerComponent } from './banner.component';
@NgModule({
  declarations: [BannerComponent],
  imports: [CommonModule, TuiNotificationModule, IconModule, RouterModule],
  exports: [BannerComponent],
})
export class BannerModule {}
