import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Валидатор для двух полей формы. Хотя бы одно поле должно быть заполнено */
export function atLeastOneValidator(controlName1: string, controlName2: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(controlName1);
    const control2 = control.get(controlName2);

    if (control1 && control2) {
      const value1 = control1.value;
      const value2 = control2.value;

      if (!value1 && !value2) {
        return { atLeastOne: true }; // Возвращает ошибку, если оба параметра пусты
      }
    }

    return null; // Ошибки нет, если заполнен хотя бы один из них
  };
}
