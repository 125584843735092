<div class="view-images-carousel">
  <div class="view-images-carousel__preview-wrapper">
    <div class="view-images-carousel__preview-button-wrapper">
      <lp-nav-button
        *ngIf="screenDimension === 'desktop'"
        [visible]="navigationService.isShowScrollLeftTopButton"
        [type]="'backward-top'"
        [variant]="'vertical'"
        (scrollPanel)="navigationService.scrollPanel($event)"
      ></lp-nav-button>

      <lp-nav-button
        *ngIf="screenDimension === 'desktop'"
        [visible]="navigationService.isShowScrollRightBottomButton"
        [type]="'forward-bottom'"
        [variant]="'vertical'"
        (scrollPanel)="navigationService.scrollPanel($event)"
      ></lp-nav-button>
    </div>

    <tui-scrollbar id="images-carousel-preview-scroll" [hidden]="true" class="view-images-carousel__preview">
      <div class="view-images-carousel__preview">
        <ng-container *ngIf="imageIds.length > 0">
          <div
            #preview
            *ngFor="let imageId of imageIds; let imageIndex = index"
            [id]="generatePreviewId(imageIndex)"
            class="img-wrapper view-images-carousel__preview_img"
            [class.view-images-carousel__preview_disabled]="imageIndex !== selectedImgIndex"
          >
            <img
              [src]="imageId | getImageSrc : 'Preview image' : 'thumbnail' : productUnionId"
              alt="Product images"
              (click)="changeImageIndex(imageIndex)"
            />
          </div>
        </ng-container>

        <ng-container *ngIf="imageIds.length < maxImagesLength">
          <div
            *tuiRepeatTimes="let index of maxImagesLength - imageIds.length"
            class="img-wrapper view-images-carousel__preview_img"
            style="cursor: default"
          ></div>
        </ng-container>
      </div>
    </tui-scrollbar>
  </div>

  <div tuiCarouselButtons class="view-images-carousel__wrapper" (touchstart.passive.stop)="(0)">
    <lp-grade-tag *ngIf="showGrade" [grade]="grade" [size]="isMobile ? 'm' : 'l'" [showTitle]="true"></lp-grade-tag>

    <button
      *ngIf="isDesktop && this.imageIds.length > 1"
      class="view-images-carousel__btn_left view-images-carousel__btn"
      (click)="navigate(-1)"
      [disabled]="imageIds.length === 0"
    >
      <tui-svg src="tuiIconChevronLeftLarge"></tui-svg>
    </button>

    <tui-carousel [(index)]="selectedImgIndex" [draggable]="true" (indexChange)="onCarouselIndexChange($event)">
      <ng-container *ngFor="let imageId of imageIds; let i = index">
        <div *tuiItem class="img-wrapper view-images-carousel__wrapper_img">
          <img
            *ngIf="downloadedPhotoIndexes.includes(i)"
            [src]="imageId | getImageSrc : 'Full image' : 'middleSize' : productUnionId"
            alt="Product images"
          />
        </div>
      </ng-container>
    </tui-carousel>

    <button
      *ngIf="isDesktop && imageIds.length > 1"
      class="view-images-carousel__btn_right view-images-carousel__btn"
      (click)="navigate(1)"
      [disabled]="imageIds.length === 0"
    >
      <tui-svg src="tuiIconChevronRightLarge"></tui-svg>
    </button>

    <tui-pagination
      *ngIf="isMobile && imageIds.length > 1"
      class="view-images-carousel__wrapper_pagination"
      size="s"
      [length]="imageIds.length"
      [(index)]="selectedImgIndex"
    ></tui-pagination>
  </div>
</div>
