import { TuiDay, TuiTime } from '@taiga-ui/cdk';

/**
 * Утилита для приведения типа Date к типу [TuiDay, TuiTime]
 *
 * @param {Date} date
 */
export const convertDateToTuiDayAndTuiTime = (date?: Date | string): [TuiDay | undefined, TuiTime | undefined] => {
  if (!date) {
    return [undefined, undefined];
  }

  const dateNormalize = new Date(date);
  const dateResult = new TuiDay(dateNormalize.getFullYear(), dateNormalize.getMonth(), dateNormalize.getDate());
  const timeResult = new TuiTime(dateNormalize.getHours(), dateNormalize.getMinutes());

  return [dateResult, timeResult];
};
