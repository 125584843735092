import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResizableBaseComponent } from '@src/app/components';
import { inputPhoneFormValidator } from '@src/app/modules/phone';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import { BreakpointObserverHelperService, AlertService } from '@src/core/services';
import { lastValueFrom, takeUntil } from 'rxjs';
import { isFileContact } from '@src/utils';
import { requireContactsValidator } from '@src/app/modules/contacts';
import { atLeastOneValidator } from '@src/app/validators';

import { RegistrationService } from '../../services/registration.service';
import { FormDataControls, RegistrationFormContactUI, RegistrationFormUI } from '../../types';

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent extends ResizableBaseComponent implements OnInit, OnChanges {
  @Input() associationId?: string;
  @Input() loading: boolean = false;

  @Output() saved: EventEmitter<RegistrationFormUI> = new EventEmitter<RegistrationFormUI>();

  readonly form = new FormGroup<FormDataControls>(
    {
      firstName: new UntypedFormControl(undefined, Validators.required),
      middleName: new UntypedFormControl(undefined),
      lastName: new UntypedFormControl(undefined, Validators.required),
      phone: new UntypedFormControl(undefined, [inputPhoneFormValidator()]),
      email: new UntypedFormControl(undefined, [Validators.email]),
      contacts: new UntypedFormControl(undefined, requireContactsValidator()),
    },
    { validators: atLeastOneValidator('phone', 'email') },
  );

  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly registrationService: RegistrationService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    this.registrationService.userContacts$.pipe(takeUntil(this.destroyed$$)).subscribe(contacts => {
      this.form.get('contacts')?.patchValue(contacts);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.associationId && this.associationId) {
      this.registrationService.getUserContactsForUnion(this.associationId);
    }
  }

  async onClickSendButton(): Promise<void> {
    this.form.markAllAsTouched();
    this.form.get('phone')?.updateValueAndValidity();
    this.form.get('contacts')?.updateValueAndValidity();

    if (this.form.invalid) {
      if (this.form.get('email')?.errors?.email) {
        this.alertService.error(this.translateService.instant('common.alerts.errors.emailValidatorError'));
      } else if (this.form.errors?.['atLeastOne']) {
        this.alertService.error(
          this.translateService.instant('common.alerts.errors.fillAtLeastOne', {
            field1: this.translateService.instant('components.userInfo.fields.mobilePhone'),
            field2: this.translateService.instant('components.userInfo.fields.email'),
          }),
        );
      } else {
        this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      }
      this.loading = false;
      return;
    }

    const formData = await this.mapFormDataToApiRequestType(this.form.getRawValue());

    this.saved.emit(formData);
  }

  private async mapFormDataToApiRequestType(formData: RegistrationFormUI) {
    const { firstName, lastName, middleName, email } = formData;
    let contacts: RegistrationFormContactUI[] = [];

    if (formData.contacts) {
      const promises = formData.contacts.map(async contact => {
        if (isFileContact(contact.contactTypeId)) {
          if (contact.newDocument && this.associationId) {
            contact.value = await this.uploadDocument(contact.newDocument, this.associationId);
          }
        } else {
          contact.value = contact.contact;
        }

        return contact;
      });

      contacts = await Promise.all(promises);
    }

    const phone = formData.phone?.replace(new RegExp('[+ -]', 'g'), '');

    return <RegistrationFormUI>{
      parentOrganisationId: this.associationId,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      contacts,
    };
  }

  private async uploadDocument(document: File, parentOrganisationId: string): Promise<string | undefined> {
    if (!document) return;

    const documentId = await lastValueFrom(
      this.registrationService.addDocuments(document, document.name, document.type, parentOrganisationId),
    );

    return documentId.id;
  }
}
