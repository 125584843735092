import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TuiDay } from '@taiga-ui/cdk';
import {
  EventAdd,
  EventService as ApiEventService,
  EventWithParticipantsEdit,
  EventViewWithParticipants,
  Id,
} from '@src/api';
import { EventViewUI, EventUI, EventViewGroupUI, Relevance } from '@src/models';
import { DECISION_TYPE_CODE } from '@src/constants';
import { TuiDayTimeTransformer } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  event$ = new BehaviorSubject<EventUI | null>(null);
  events$ = new BehaviorSubject<EventViewUI[] | null>(null);

  archivalEvents$ = new BehaviorSubject<EventViewUI[] | null>(null);
  currentEvents$ = new BehaviorSubject<EventViewUI[] | null>(null);
  archivalEventsGroup$ = new BehaviorSubject<EventViewGroupUI[] | null>(null);
  currentEventsGroup$ = new BehaviorSubject<EventViewGroupUI[] | null>(null);

  currentEventsForSearch$ = new BehaviorSubject<EventViewUI[] | null>(null);
  archivalEventsForSearch$ = new BehaviorSubject<EventViewUI[] | null>(null);
  currentEventsGroupForSearch$ = new BehaviorSubject<EventViewGroupUI[] | null>(null);
  archivalEventsGroupForSearch$ = new BehaviorSubject<EventViewGroupUI[] | null>(null);

  eventsDaysForCalendar$ = new BehaviorSubject<TuiDay[] | undefined>(undefined);

  eventsForCommittee$ = new BehaviorSubject<EventViewUI[] | undefined>(undefined);

  private destroyed$$ = new Subject<void>();

  constructor(
    private eventService: ApiEventService,
    private userService: UserService,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getEventData(id: string): Observable<EventViewWithParticipants> {
    return this.eventService.getEvent(id).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  getEvent(id: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    // TODO: add params
    this.eventService
      .getEvent(id)
      .pipe(
        catchError(err => {
          this.alertService.error(
            this.translateService.instant('components.eventInfo.alerts.errors.eventByLinkNotFound'),
          );
          callback?.(false);
          return throwError(() => err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(async (event: EventUI) => {
        if (event.contactPersonId) {
          const contactPerson = await lastValueFrom(this.userService.getUserData(event.contactPersonId));
          event.contactPersonFullName = contactPerson?.fullName;
        }

        this.event$.next(event);
        callback?.(false);
      });
  }

  getEvents(callback?: (value: boolean) => void): void {
    callback?.(true);

    // TODO: add params
    this.eventService
      .getEventList(1000, 0)
      .pipe(
        catchError(err => {
          callback?.(false);
          this.alertService.error(err);
          return throwError(() => err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(async events => {
        this.events$.next(events);

        const currentEvents: EventViewUI[] = [];
        const archivalEvents: EventViewUI[] = [];

        events.forEach(event => {
          if (
            (event.dateEnd ? new Date(event.dateEnd) < new Date() : true) ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Rejected ||
            event.status === 5 // Отменено
          ) {
            return archivalEvents.push(event);
          }

          if (
            !!this.userService.authUser?.permissions?.includes('eventsAllViewing') ||
            event.createdBy === this.userService.authUser?.id ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Accepted ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Readed ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Sended ||
            event.isVisibleToAll
          ) {
            return currentEvents.push(event);
          }

          return;
        });

        currentEvents.sort((a, b) => this.sortByDateStart(a, b, true));
        archivalEvents.sort((a, b) => this.sortByDateStart(a, b, false));

        this.currentEvents$.next(currentEvents);
        this.archivalEvents$.next(archivalEvents);
        this.formatEventGroup(currentEvents, 'current', new Date());
        this.formatEventGroup(archivalEvents, 'archive');

        const eventsDaysForCalendar = this.getEventsDaysForCalendar(events);
        this.eventsDaysForCalendar$.next(eventsDaysForCalendar);

        callback?.(false);
      });
  }

  getEventsForSearch(searchQuery?: string, selectedDate?: TuiDay | null): void {
    const currentEvents = this.currentEvents$.value;
    const archivalEvents = this.archivalEvents$.value;

    if ((!searchQuery && !selectedDate) || (!currentEvents && !archivalEvents)) {
      this.resetEventsForSearch();
      return;
    }

    if (selectedDate) {
      const currentEventsForDay = currentEvents?.filter(event => this.checkEventForDay(event, selectedDate)) ?? [];
      const archivalEventsForDay = archivalEvents?.filter(event => this.checkEventForDay(event, selectedDate)) ?? [];

      this.currentEventsForSearch$.next(currentEventsForDay);
      this.archivalEventsForSearch$.next(archivalEventsForDay);
      this.formatEventGroup(currentEventsForDay, 'current', new Date(), true);
      this.formatEventGroup(archivalEventsForDay, 'archive', undefined, true);
      return;
    }

    if (searchQuery) {
      const currentEventsForSearch =
        currentEvents?.filter(
          event => event.subject && event.subject.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1,
        ) ?? [];
      const archivalEventsForSearch =
        archivalEvents?.filter(
          event => event.subject && event.subject.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1,
        ) ?? [];

      this.currentEventsForSearch$.next(currentEventsForSearch);
      this.archivalEventsForSearch$.next(archivalEventsForSearch);
      this.formatEventGroup(currentEventsForSearch, 'current', new Date(), true);
      this.formatEventGroup(archivalEventsForSearch, 'archive', undefined, true);
    }
  }

  getEventsDaysForCalendar(events: EventViewUI[]): TuiDay[] {
    // TODO: after, replace with eventListByPeriod
    let eventsDaysForCalendar: TuiDay[] = [];
    if (events) {
      events.forEach(event => {
        const eventDays = this.getEventDays(event)?.map(date => TuiDayTimeTransformer.dateToTuiDay(date));
        if (eventDays) {
          eventsDaysForCalendar = [...new Set(eventsDaysForCalendar.concat(...eventDays))];
        }
      });
    }
    return eventsDaysForCalendar;
  }

  getEventsForCommittee(committeeId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.eventService
      .eventListByCommitteeId(committeeId)
      .pipe(
        catchError(err => {
          callback?.(false);
          this.alertService.error(err);
          return throwError(() => err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(events => {
        events.sort((a, b) => this.sortByDateStart(a, b));
        this.eventsForCommittee$.next(events);
        callback?.(false);
      });
  }

  getEventMembersFile(eventId: string, sendToBot?: boolean): Observable<Blob> {
    return this.eventService.eventParticipantsFile(eventId, sendToBot).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  createEvent(data: EventAdd): Observable<Id> {
    return this.eventService.eventAdd(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  editEvent(data: EventWithParticipantsEdit): Observable<Id> {
    return this.eventService.eventEdit(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  deleteEvent(eventId: string): Observable<void> {
    return this.eventService.deleteEvent(eventId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  deactivateEvent(eventId: string): Observable<void> {
    return this.eventService.dectivateEvent(eventId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  resendNotification(eventId: string, memberIds: string[]): Observable<void> {
    return this.eventService.resendNotification({ id: eventId, sendTo: memberIds }).pipe(
      catchError(err => {
        this.alertService.error(this.translateService.instant(err.message), {
          autoClose: 10 * 1000,
        });

        return throwError(() => err);
      }),
      tap(() => {
        this.alertService.success(
          this.translateService.instant('components.eventInfo.alerts.successes.resendNotification'),
          {
            autoClose: 10 * 1000,
          },
        );
      }),
      takeUntil(this.destroyed$$),
    );
  }

  resetEvent(): void {
    this.event$.next(null);
  }

  resetEventsForSearch(): void {
    this.currentEventsForSearch$.next(null);
    this.archivalEventsForSearch$.next(null);
    this.currentEventsGroupForSearch$.next(null);
    this.archivalEventsGroupForSearch$.next(null);
  }

  resetEventsForCommittee(): void {
    this.eventsForCommittee$.next(undefined);
  }

  resetAll(): void {
    this.events$.next(null);
    this.archivalEvents$.next(null);
    this.currentEvents$.next(null);
    this.eventsDaysForCalendar$.next(undefined);
    this.resetEventsForSearch();
  }

  acceptEvent(id: string) {
    return lastValueFrom(this.eventService.acceptEvent({ id })).then();
  }

  declineEvent(id: string) {
    return lastValueFrom(this.eventService.declineEvent({ id })).then();
  }

  addEventToCalendar(id: string, sendToBot?: boolean) {
    return this.eventService.calendar(id, sendToBot);
  }

  private checkEventForDay(event: EventViewUI, day: TuiDay): boolean {
    if (!event.dateStart || !event.dateEnd) return false;

    const startDate = new Date(event.dateStart);
    const endDate = new Date(event.dateEnd);

    const eventStartDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    const eventEndDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0);
    const calendarDay = new Date(day.year, day.month, day.day, 0, 0, 0, 0);

    if (eventStartDay <= calendarDay && calendarDay <= eventEndDay) return true;

    return false;
  }

  private getEventDays(event: EventViewUI): Date[] | undefined {
    if (!event.dateStart || !event.dateEnd) return;

    const startDate = new Date(event.dateStart);
    const endDate = new Date(event.dateEnd);
    const dateArray: Date[] = [];

    const currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  private sortByDateStart(a: EventUI, b: EventUI, asc: boolean = true) {
    const dateStartA = a.dateStart ? new Date(a.dateStart) : undefined;
    const dateStartB = b.dateStart ? new Date(b.dateStart) : undefined;

    if (!dateStartA || !dateStartB) return 0;

    return asc ? dateStartA.getTime() - dateStartB.getTime() : dateStartB.getTime() - dateStartA.getTime();
  }

  private formatEventGroup(
    events: EventViewUI[],
    eventRelevance?: Relevance,
    startFromDate?: Date,
    search: boolean = false,
  ): void {
    const eventMonths: EventViewGroupUI[] = [];

    events.map(event => {
      const eventDateStart = event.dateStart ? new Date(event.dateStart) : undefined;
      let dateStart = eventDateStart;

      if (eventDateStart && startFromDate) {
        dateStart = eventDateStart.getTime() < startFromDate.getTime() ? startFromDate : eventDateStart;
      }

      const dateEnd = event.dateEnd ? new Date(event.dateEnd) : undefined;

      if (!dateEnd || !dateStart) return;

      const dates: Date[] = [];

      const monthsLength =
        dateEnd.getFullYear() * 12 + dateEnd.getMonth() - (dateStart.getFullYear() * 12 + dateStart.getMonth());

      for (let i = dateStart.getMonth(); i < monthsLength + dateStart.getMonth() + 1; i++) {
        const newDate = new Date(dateStart.getFullYear(), i, 1);
        dates.push(newDate);
      }

      dates.forEach(date => {
        const eventGroup = eventMonths.find(
          eventGroup => eventGroup.month === date?.getMonth() && eventGroup.year === date?.getFullYear(),
        );

        if (eventGroup) {
          eventGroup.events.push(event);
        } else {
          const newEventGroup = {
            events: [event],
            month: date.getMonth(),
            year: date.getFullYear(),
          };

          eventMonths.push(newEventGroup);
        }
      });
    });

    if (search) {
      if (eventRelevance === 'current') {
        this.currentEventsGroupForSearch$.next(eventMonths);
      } else {
        this.archivalEventsGroupForSearch$.next(eventMonths);
      }

      return;
    }

    if (eventRelevance === 'current') {
      this.currentEventsGroup$.next(eventMonths);
    } else {
      this.archivalEventsGroup$.next(eventMonths);
    }
  }
}
