import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Clipboard } from '@angular/cdk/clipboard';
import { takeUntil } from 'rxjs';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { SendContactsRequestDto } from '@src/api';
import { AlertService, BreakpointObserverHelperService, OrganisationService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { inputPhoneFormValidator } from '@src/app/modules/phone';
import { AuthUserService } from '@src/app/modules/auth';
import { Optional } from '@src/types/utils';

import { LoyaltyProgramProductTerms } from '../../../models';
import { LoyaltyProgramApiService } from '../../../services';
import { LoyaltyProgramProductWithDiscountGroupId } from '../../../models/LoyaltyProgramProduct';
import { QR_CODE_IMAGE_WIDTH } from '../../constants';

import { ContactsFormType } from './types';

@Component({
  selector: 'lp-view-discount-dialog',
  templateUrl: './discount-dialog.component.html',
  styleUrls: ['./discount-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDialogComponent extends ResizableBaseComponent {
  contactsFormVisible = false;
  contactsForm: FormGroup<ContactsFormType>;

  readonly qrCodeImageWidth = QR_CODE_IMAGE_WIDTH;

  /** Признак использования unionId в запросах */
  private useUnionId = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly clipboard: Clipboard,
    private readonly alertService: AlertService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, LoyaltyProgramProductWithDiscountGroupId>,
    private readonly translateService: TranslateService,
    private readonly loyaltyProgramApiService: LoyaltyProgramApiService,
    private readonly authUserService: AuthUserService,
    private readonly organisationService: OrganisationService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.contactsForm = new FormGroup({
      fio: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, inputPhoneFormValidator()]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    const currentUser = this.authUserService.user;
    this.contactsForm.patchValue({
      fio: currentUser?.fullName,
      phone: currentUser?.phone,
      email: currentUser?.email,
    });

    this.organisationService.currentParentOrganisation$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setUseUnionId();
    });
  }

  get product() {
    return this.context.data.product;
  }

  get discountGroupId() {
    return this.context.data.discountGroupId;
  }

  get discount(): Optional<LoyaltyProgramProductTerms> {
    return this.discountGroupId
      ? this.product?.terms.find(term => term.id === this.discountGroupId)
      : this.product?.terms?.[0];
  }

  get isQrCodeImage(): boolean {
    return !!this.discount?.qrCodeImage;
  }

  get productId() {
    return this.product.id;
  }

  get requestLeadContacts() {
    return this.product.cost?.requestLeadContacts && this.authUserService.user?.id !== this.product.createdBy;
  }

  /** ID ассоциации внешней базы */
  get externalUnionId(): string | undefined {
    return this.useUnionId ? this.product?.unionId : undefined;
  }

  /** Заполняем признак использования unionId */
  setUseUnionId() {
    this.useUnionId = this.product?.unionId
      ? this.organisationService.currentParentOrganisation$.value?.id !== this.product.unionId
      : false;
    this.cdr.markForCheck();
  }

  copyPromoCode(): void {
    if (this.clipboard.copy(this.discount?.promoCode ?? '')) {
      this.alertService.success(
        this.translateService.instant('components.loyaltyDiscountDialog.alerts.successes.copyCode'),
      );
    }
  }

  saveRedirectLink(link: string) {
    if (!this.product?.id) return;

    this.loyaltyProgramApiService.saveRedirectLink(this.product.id, link, this.externalUnionId);
  }

  sendContacts() {
    this.contactsForm.markAllAsTouched();

    if (this.contactsForm.invalid) {
      this.alertService.error(
        this.translateService.instant('components.loyaltyDiscountDialog.alerts.errors.requiredFields'),
      );
      return;
    }

    this.contactsForm.disable();

    this.loyaltyProgramApiService
      .sendContacts(
        {
          ...{ productId: this.productId },
          ...(this.contactsForm.value as SendContactsRequestDto), // TODO: удалить SendContactsRequestDto когда вернем nullable в модели бэка
        },
        this.externalUnionId,
      )
      .subscribe(() => {
        this.contactsFormVisible = false;
        this.contactsForm.enable();

        this.alertService.success(
          this.translateService.instant('components.loyaltyDiscountDialog.alerts.successes.contactsSent'),
        );

        this.cdr.markForCheck();
      });
  }
}
