import { Inject, Injectable, Injector } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { catchError, throwError } from 'rxjs';
import { AlertService } from '@src/core/services';
import { LoyaltyCodesForProductView } from '@src/api';

import { LoyaltyProgramProduct } from '../models';
import {
  DiscountDialogComponent,
  DiscountGroupsDialogComponent,
  UniqueCodeDialogComponent,
} from '../lp-view-product/components';
import {
  LoyaltyProgramProductWithDiscountGroupId,
  LoyaltyProgramProductWithExternalUnionId,
} from '../models/LoyaltyProgramProduct';

import { LoyaltyProgramApiService } from './loyalty-program-api.service';

@Injectable({
  providedIn: 'root',
})
export class LpDiscountDialogService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly translateService: TranslateService,
    private readonly loyaltyProgramApiService: LoyaltyProgramApiService,
    private readonly alertService: AlertService,
  ) {}

  openDiscountGroupsDialog(product: LoyaltyProgramProduct, externalUnionId?: string) {
    const data: LoyaltyProgramProductWithExternalUnionId = {
      product,
      externalUnionId,
    };

    this.dialogService
      .open<string>(new PolymorpheusComponent(DiscountGroupsDialogComponent, this.injector), {
        label: this.translateService.instant('components.loyaltyProgram.dialogs.discountGroupsHeader'),
        data,
        size: 'l',
      })
      .subscribe();
  }

  openDiscountDialogWithRequest(product: LoyaltyProgramProduct, externalUnionId?: string, discountGroupId?: string) {
    if (!product?.id) return;

    const data: LoyaltyProgramProductWithDiscountGroupId = {
      product,
      discountGroupId,
    };

    const selectedDiscountGroup = discountGroupId
      ? product.terms.find(term => term.id === discountGroupId)
      : product.terms[0];

    if (!selectedDiscountGroup?.id) return;

    this.loyaltyProgramApiService.getProductCode(selectedDiscountGroup.id, externalUnionId).subscribe(groupCode => {
      if (groupCode) {
        this.alertService.success(
          this.translateService.instant('components.loyaltyProgram.alerts.successes.saveProductCode'),
        );

        if (selectedDiscountGroup) {
          selectedDiscountGroup.promoCode = groupCode.discountCode ?? '';
          selectedDiscountGroup.description = groupCode.discountOptions ?? '';
        }
      }

      this.openDiscountDialogByProduct(data);
    });
  }

  openDiscountDialogByProduct(data: LoyaltyProgramProductWithDiscountGroupId) {
    this.dialogService
      .open(new PolymorpheusComponent(DiscountDialogComponent, this.injector), {
        label: this.translateService.instant('components.loyaltyProgram.dialogs.discountCodeHeader'),
        data,
      })
      .subscribe();
  }

  // TODO: Привести LoyaltyCodesForProductView к LoyaltyProgramProductWithDiscountGroupId и использовать openDiscountDialogByProduct
  openDiscountDialogByCode(data: LoyaltyCodesForProductView) {
    this.dialogService
      .open(new PolymorpheusComponent(UniqueCodeDialogComponent, this.injector), {
        label: this.translateService.instant('components.loyaltyProgram.dialogs.discountCodeHeader'),
        data,
      })
      .subscribe();
  }
}
