<div class="policy-page">
  <div class="policy-page__title">{{ 'components.policy.labels.title' | translate }}</div>

  <div class="policy-page__links">
    <!-- Политика конфиденциальности ассоциации -->
    <app-download-link
      *ngIf="privacyPolicyLink"
      class="download-link"
      [fileLink]="privacyPolicyLink"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.association' | translate : { value: associationShortName ?? '' } }}
    </app-download-link>

    <!-- Политика конфиденциальности -->
    <app-download-link class="download-link" fileLink="assets/docs/Политика конфиденциальности.pdf">
      {{ 'components.policy.buttons.unions' | translate }}
    </app-download-link>

    <!-- Пользовательское соглашение -->
    <app-download-link class="download-link" fileLink="assets/docs/Пользовательское соглашение.pdf">
      {{ 'components.policy.buttons.userAgreement' | translate }}
    </app-download-link>

    <!-- Согласие на обработку персональных данных -->
    <app-download-link class="download-link" fileLink="assets/docs/Согласие на обработку персональных данных.pdf">
      {{ 'components.policy.buttons.personalData' | translate }}
    </app-download-link>

    <!-- Согласие на получение материалов информационного характера -->
    <app-download-link
      class="download-link"
      fileLink="assets/docs/Согласие на получение материалов информационного характера.pdf"
    >
      {{ 'components.policy.buttons.informationalMaterials' | translate }}
    </app-download-link>

    <!-- Политика конфиденциальности Яндекс (для SmartCaptcha) -->
    <app-download-link
      *ngIf="enterType === EnterType.Email && !isCordova"
      class="download-link"
      fileLink="https://yandex.ru/legal/smartcaptcha_notice/"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.yandex' | translate }}
    </app-download-link>
  </div>

  <button tuiButton class="policy-page__button-close" size="m" (click)="closePage()">
    {{ 'common.buttons.close' | translate }}
  </button>
</div>
